@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes pulse{
	from{
		transform: scale(1) translate(-50%,-50%);
	}
	to{
		transform: scale(1.1) translate(-50%,-50%);
	}
}