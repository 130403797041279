@import 'reset';
@import 'animations';
@import 'variables';

*{
	box-sizing: border-box;
}

body, input, textarea{
	font-family: 'Overpass', sans-serif;
	font-weight: 300;
	font-size: 20px;
	color: $black;
	background: $white;
	line-height: 1.6;
	letter-spacing: -0.05rem;
	padding: 0;
	appearance: none;
}

input::placeholder, textarea::placeholder{
	color: #aaa;
}

#root{
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}

nav{
	ul{
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
		margin-top: 0;
		li{
			margin-right: 20px;
		}
	}
}

a{
	color: $black;
	text-decoration: none;
}


@media (max-width: 600px){
	body, input, textarea{
		font-size: 18px;
	}
}