@import '../variables';

.SingleNote{
	padding: 60px 75px;
	box-shadow: 0 0 5px 0px transparentize($black, 0.8);
	position: relative;

	form{
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		input, textarea{
			background: transparent;
			border: 0;
			appearance: none;
			outline: none;
			width: 100%;
		}

		.title{
			font-size: 40px;
			font-weight: 700;
			margin-bottom: 30px;
			line-height: 1;
			letter-spacing: -0.1rem;
		}

		textarea{
			width: 100%;
			min-height: 200px;
			flex: 1;
			resize: none;
			letter-spacing: -0.05rem;
		}
	}
}

.SingleNote.inactive{

	display: flex;
	align-items: center;
	justify-content: center;

	p{
		opacity: 0.4;
		font-size: 25px;
		text-align: center;
		line-height: 1.3;
	}

}


@media (max-width: 1000px){

	.SingleNote{
		padding: 75px 6vw 5vw 6vw;
	}

}


@media (max-width: 600px){
	.SingleNote form .title{
		font-size: 30px;
		margin-bottom: 20px;
	}

	.SingleNote.inactive p{
		font-size: 20px;
	}
}