@import '../variables';

.SyncStatus{
	position: absolute;
	right: -54px;
	top: -40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
	opacity: 0.7;

	svg{
		width: 28px;
		height: 28px;
		margin-left: 5px;
	}

	svg.spin{
		animation: spin 2s linear infinite;
	}
}


@media (max-width: 1000px){
	
	.SyncStatus{
		top: -63px;
		right: calc(-6vw + 11px);
	}

}

@media (max-width: 600px){

	.SyncStatus{
		font-size: 16px;
	}
	
}