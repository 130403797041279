@import '../variables';

.CreateNote{
	display: flex;
	flex-direction: row;
	position: sticky;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px;

	input[type=text]{
		border: 1px solid #ddd;
		border-right: 0 !important;
		background: $purewhite;
		padding: 10px 15px;
		appearance: none;
		flex: 1;
		width: 0;
		transition: 200ms;
	}

	input[type=text]:focus{
		outline: none;
		border: 1px solid $black;
	}

	input[type=submit]{
		min-width: 60px;
		background: $black;
		color: $purewhite;
		font-weight: 700;
		border: 0;
		cursor: pointer;
		transition: 200ms;
	}

	@media(hover: hover){
		input[type=submit]:hover{
			opacity: 0.9;
		}
	}
}