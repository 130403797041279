@import '../variables';

.NotesList{
	background: $white;
	position: relative;
	letter-spacing: -0.05rem;

	ul{
		margin: 0;
		padding: 0;
		list-style-type: none;
		height: 100%;
		overflow: auto;

		li{
			position: relative;
			border-bottom: 1px solid transparent;
			border-top: 1px solid transparent;
			border-left: 1px solid transparent;
			transition: 200ms;

			a{
				display: block;
				width: 100%;
				padding: 15px 20px;

				.timestamp{
					font-weight: normal;
					font-size: 14px;
					opacity: 0.5;
					display: flex;
					flex-direction: row;
					align-items: center;

					svg{
						margin-right: 5px;
						font-size: 10px;
					}
				}
			}

			.delete{
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
				cursor: pointer;
				font-weight: 700;
				color: #aaa;
				font-size: 16px;
				line-height: 1;
				transition: 200ms;
			}
			
			@media(hover: hover){
				.delete:hover{
					color: $red;
					transform: translateY(-50%) scale(1.2)
				}
			}

		}

		li:last-child{
			margin-bottom: 170px;
		}

		@media(hover: hover){
			li:hover{
				background: $purewhite;
			}
		}

		li.active{
			background: $purewhite;
			border-left: 5px solid $black;
			border-top: 1px solid #eee;
			border-bottom: 1px solid #eee;
		}
	}
}

@media (max-width: 1000px){

	[data-menuopen=true] .NotesList{
		transform: translateX(0);
	}

	.NotesList{
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		z-index: 10;
		box-shadow: 0 0 5px 0px transparentize($black, 0.8);
		transform: translateX(-100%);
		transition: 300ms;
	}

}